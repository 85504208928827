export const SERVER_URL = 'https://sofia-supermarkets-api-proxy.stefan-bratanov.workers.dev/products/flat/alcohol?offers=true';

export const CATEGORIES = [
    {
        value: 'deals',
        title: '(%) Най-оферта',
        img: 'flamepercent'
    },
    {
        value: 'deals-price',
        title: '(лв) Най-оферта',
        img: 'flamelv'
    },
    {
        value: 'beer',
        title: 'Бира',
        img: 'beer'
    },
    {
        value: 'whiskey',
        title: 'Уиски',
        img: 'whiskey'
    },
    {
        value: 'vodka',
        title: 'Водка',
        img: 'vodka'
    },
    {
        value: 'rakia',
        title: 'Ракия',
        img: 'rakia'
    },
    {
        value: 'wine',
        title: 'Вино',
        img: 'vino'
    },
    {
        value: 'other',
        title: 'Други',
        img: 'other'
    }
]

export const isEmptyArray = (array) => {
    return Array.isArray(array) && array.length === 0
}