import './Header.css';
import {Link} from "react-router-dom";
import Search from './Search';

function Header() {
    return (
        <>
            <div className='header-wrapper'>
                <div className='header'>
                    <div className='logo-title'>
                        <h1 className='heading-title'>На Оферта, Алкохол промоции</h1>
                        <h2 className='heading-title'>Сравни топ Промоции, в супермаркети, бира, уиски, водка, ракия, вино</h2>
                        <Link className='logo-header' to="/" aria-label="home page"></Link>
                    </div>
                    <Search/>                
                </div>            
            </div>
            <div className="title-info">
                <span className="title-info-text">Сравни Топ Промоциите!</span>
            </div>
        </>
    ); 
}

export default Header;