import './Tile.css';
import tmarket from '../images/tmarketlogo.png';
import billa from '../images/billalogo.png';
import kaufland from '../images/kauflandlogo.png';
import lidl from '../images/lidllogo.png';
import ff from '../images/fflogo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isEmpty } from 'underscore';

function Tile(props) {
    const { displayDiscountInCurrency, product, text } = props;

    const { supermarket, name, quantity, price, oldPrice, discount, picUrl, validFrom, validUntil } = product;
    // eslint-disable-next-line
    const showClass = (typeof product.showClass === undefined) ? '' : product.showClass;
    const separatedPrice = price.toString().split('.');
    const separatedOldPrice = oldPrice.toString().split('.');
    const isFromDateOlder = new Date() <= new Date(validFrom);
    const monthUntil = validUntil ? '/' + validUntil.split('-')[1] : '';
    const dayUntil = validUntil ? 'до ' + validUntil.split('-')[2] : '';
    const monthFrom = validFrom && isFromDateOlder ? '/' + validFrom.split('-')[1] + ' ' : '';
    const dayFrom = validFrom && isFromDateOlder ? 'от ' + validFrom.split('-')[2] : '';
    const validUntilContent = dayFrom + monthFrom + dayUntil + monthUntil

    return (
        <article className={"tile " + showClass || ''}>
            <div className="product-img">
                <LazyLoadImage
                    alt={name}
                    src={picUrl}
                />
            </div>

            {displayDiscountInCurrency ? <div className="badge">-{parseFloat((oldPrice - price).toFixed(2))} лв</div> : <div className="badge">-{discount}%</div>}

            <div className="logo">
                <LazyLoadImage
                    alt={supermarket + "logo"}
                    src={getLogo(supermarket)}
                />
            </div>

            <div className="info-wrapper">
                <div className="name">
                    <span className="name-text">{name}</span>
                </div>

                <div className="quantity">{quantity}</div>

                <div className="price-info-wrapper">
                    <div className="old-price">
                        {separatedOldPrice[0]}.<sup className='old-price-smalls'>{separatedOldPrice[1]}</sup>
                        <span className="old-price-currency">лв</span>
                    </div>
                    <div className="price">
                        {separatedPrice[0]}.<sup className='price-smalls'>{separatedPrice[1]}</sup>
                        <span className="price-currency">лв</span>
                    </div>
                </div>

                <div className="validUntil" style={isEmpty(validUntilContent) ? { visibility: "hidden" } : {}}>{isEmpty(validUntilContent) ? "foobar" : validUntilContent}</div>
                <div className="productText">{text}</div>
            </div>
        </article>
    );
}

function getLogo(supermarket) {
    supermarket = supermarket.toLowerCase();
    switch (supermarket) {
        case 'billa':
            return billa;
        case 'fantastico':
            return ff;
        case 'kaufland':
            return kaufland;
        case 't-market':
            return tmarket;
        case 'lidl':
            return lidl;
        default:
            return '';
    }
}

export default Tile;
