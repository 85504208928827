import './Menu.css';
import { Link } from 'react-router-dom';
import {CATEGORIES} from './constants/CONST';

function Menu(props) {
    const { activeCategory } = props;

    return (
        <div className='buttons'>
            {CATEGORIES.map((category, idx) => (
                <Link key={idx} to={'/category/' + category.value} className={activeCategory === category.value ? 'button-link button-link-active' : 'button-link'}>
                    <nav className='btn-image-wrapper'>
                        <img className='btn-image' src={require(`../images/${category.img}.png`)} alt={category.title} />
                    </nav>
                    <div className='button-title'>{category.title}</div>
                </Link>
            ))}
        </div>
    );
}

export default Menu;
