function About() {
    return (
        <section style={{ padding: 10 }}>
            <h1>Информация за сайта</h1>
            <article>
                <div>
                    "На оферта" не е направен с цел печалба и не получава дивидент от показването на продуктите от използваните супермаркети.
                    Сайтът е направен с идеята да помага на потребителите да взимат по- информирани решения при закупуването на алкохол.
                    Промоциите са взети от сайтовете или брошурите на посочените супермаркети и ги сравнява, спрямо процентна промоция (категория %)
                    или абсолютна стойност (категория лв). Всички останали категории са разделени по вид алкохол: бира, уиски, водка, вино,
                    и други (където са включени не толкова популярни алкохоли, като джин, мента, мастика и подобни).
                </div>
                <div>
                    *Хубаво е да се има в предвид, че сайтът обработва продуктите и брошурите динамично, което би могло да резултира в грешки,
                    като например някой от промоциите да не е актуална в магазина или процента промоция или цената да са различни.
                </div>
                <div>
                    **"На оферта" е направен с цел забавление и лична полза. При всякакви забележки, мнения, коментари, може да се обърнете към нас.
                </div>
                <div>
                    ***Консумирайте алкохол за удоволствие и с мярка :)
                </div>

            </article>
            <p></p>
            <article>
                <div>
                    "Na oferta" is not made with the purpose of profit and does not receive any dividends from the used supermarkets, although
                    showing their products, therefore advertising them. The main idea of the site is to help the customers take more informed decisions
                    while buying alcoholic beverages. All promotions are taken either from the supermarkets' websites or their brochures, then compared
                    according to their current promotion (old price vs new price). All categories compare the % change in the price and sorts them in descending order.
                    Categories are as follows: beer, whiskey, vodka, wine, rakya and others (djin, mastika, menta etc.).
                </div>
                <div>
                    *It's good to be known by the customers that the site compares information from online sources automatically, which could result in some errors,
                    or misleading information like not present promotion in the supermarket or discrepancy in the price/promotion.
                </div>
                <div>
                    **"Na oferta" is made for fun and personal usage. If there is something wrong, want to comment, share opinion just contact us.
                </div>
                <div>
                    ***Consume for pleasure and don't get drunk! :)
                </div>
            </article>
        </section>
    );
}

export default About;
