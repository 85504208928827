function Contact() {
    return (
        <section style={{ padding: 10 }}>
            <article>За контакти може да пишете на емайла naoferta.net@gmail.com или цъкнете Send Email най-долу на страницата.</article>
            <p></p>
            <article>For contacts please send email to naoferta.net@gmail.com" or click the Send Email link at the bottom of the page.</article>
        </section>
    );
}

export default Contact;
