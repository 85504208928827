import { groupBy, findWhere, first, compact } from 'underscore';
import Tile from './Tile';
import { CATEGORIES, isEmptyArray } from './constants/CONST';
import './Tiles.css';

const bluff = [
    "Stephans Bräu e висококачествена немска бира, приготвена от естествени съставки – хмел, малц, бирена мая и вода.",
    "Купи УИСКИ BRAVE HEART ШОТЛАНДСКО 700МЛ/12/ от категория Уиски и бърбън в онлайн супермаркет BG MARKET на цена от , с безплатна доставка до дома и офиса.",
    "Бира Corona Extra стек 6 бутилки x 355ml 4.6% Бира Corona /Корона/ е марка мексиканска бира, стил американски лагер, която се произвежда в Мексико, ...",
    "Да научим всичко за Розето, като започнем от различните стилове и грозде, ... Първо, при розето се използват и бели и червени сортове грозде.",
    "Leffe Blond is an authentic blond abbey beer with a slight hint of bitterness to it. ... Shortly after, the Leffe brewery was back in full effect.",
    "Съставки: Вода; ЕЧЕМИЧЕН МАЛЦ; ЕЧЕМИК; Хмелов екстракт Хранителна стойност за 100 ml продукт: Енергийна стойност: 153 kJ/ 36 kcal; Мазнини: < 0.1 g/100 ml; ...",
    "Атрактивен червен цвят с елегантен блясък. Жив и пиперлив плодов аромат.Нежна кадифена атака, разкриваща хармоничен вкус на ранни горски плодове, ...",
    "История[редактиране | редактиране на кода]. Началото на историята на бирата „Каменица“ е свързано с тримата швейцарци Рудолф Фрик, Фридрих Сулцер и Кристиан ...",
    "J&B Rare е шотландско уиски създадено от внимателно подбрани малцови уискита от долината на река Спей, които формират основата на бленда и отговарят за ...",
    "Tuborg is a Danish brewing company founded in 1873 on a harbour in Hellerup, an area North of Copenhagen, Denmark. Since 1970 it has been part of the ...",
    "Загорка Специално. За класически Загорка вкус. Защото обичаш бира. ... Загорка Специално. За класически Загорка вкус. Защото обичаш бира.",
    "Всичко започва през 1888 г., когато Paul Jones, Jr., основателят на Four Roses Bourbon е поразен от красотата на южняшка красавица.",
    "Температурно съхрание: Съхранение на сухо и хладно място. Да не се излага на пряка слънчева светлина и висока температура.Производител/ Дистрибутор :William ...",
    "Glen Grant is a malt with a bit of a split personality in some ways. On one hand, in its best known form, it is a large-volume, hugely popular young malt ...",
    "Ballantine's 12 Year Old (Gold Seal) е богато, гладко и комплексно уиски, блендирано от специално селектирани малцови и зърнени уискита.",
    "Уиски Джак Даниелс 700ml 40%. Джак Даниел'с Тенеси уиски е една от най-известните марки за алкохол в света. Притежава уникален дизайн с черен етикет и ...",
    "Зельоная марка е класическа руска водка с оригинален външен вид и опаковка типична за 50-те години на миналия век. Кедрова е уникална за българския ...",
    "Коняк Курвоазие VS е френски коняк,известен още и като коняка на Наполеон. Легендата гласи, че когато Наполеон е ... Коняк Courvoisier VS 700ml 40%."
]

function Tiles(props) {
    const { products, category, setProducts, overrideNoProductsText } = props;
    const availableSupermarkets = Object.keys(groupBy(products, product => product.supermarket.toLowerCase())).sort();

    const handleClick = (e, supermarket) => {
        const isSelected = e.target.className === 'selected';
        const filters = e.target.parentElement.children;
        setProducts(prevProducts => ({
            ...prevProducts,
            [category]: filterProducts(supermarket, isSelected)
        }));

        for (let i = 0; i < filters.length; i++) {
            filters[i].classList.remove('selected');
        }
        e.target.classList.toggle('selected', !isSelected);
    }

    const filterProducts = (supermarket, isSelected) => {
        return products.map((product) => {
            product.showClass = product.supermarket.toLowerCase() !== supermarket.toLowerCase() && !isSelected ? 'hidden' : '';
            return product;
        })
    }

    if (isEmptyArray(products)) {
        const categoryTitle = findWhere(CATEGORIES, { value: category })?.title
        return (<div className="no-products">{first(compact([overrideNoProductsText, "Няма продукти за категория " + categoryTitle]))}</div>)
    }

    return (
        <div className="tiles-wrapper">
            <section className="filters">
                {availableSupermarkets && availableSupermarkets.map((supermarket, idx) => (
                    <nav key={idx} id={"filter-" + supermarket} onClick={e => handleClick(e, supermarket)}></nav>
                ))}
            </section>

            <section className="tiles">
                {products && products.map((product, idx) => (
                    <Tile key={idx} text={bluff[idx % bluff.length]} product={product} displayDiscountInCurrency={category === 'deals-price'} />
                ))}
            </section>
        </div>
    );
}

export default Tiles;
