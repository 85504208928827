import './SearchSuggestions.css';
import React from 'react';

function SearchSuggestions(props) {
    const {isFocus, suggestions, input} = props;

    return (
        <ul className={isFocus && (suggestions.length > 0 || input.length > 2) ? 'search-suggestions' : 'hidden'}>
            {suggestions.length > 0 ? <a href={'/search?q=' + input}>
                <li className="suggestion view-all"><b>Виж всички ({suggestions.length})</b></li>
            </a> : <li className="suggestion view-all"><b>Няма резултати</b></li>}
            
            {suggestions && suggestions.map((suggestion, suggestionidx) => (
                <a key={suggestionidx} href={'/search?q=' + encodeURIComponent(suggestion)}>
                    <li className='suggestion'>
                        {suggestion}
                    </li>
                </a>
            ))}
        </ul>
    );
}

export default SearchSuggestions;
