import './Footer.css';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <footer {...props}>
            <div className="footer-info-wrapper">
                <p><a href="mailto:naoferta.net@gmail.com">Send Email</a></p>
                <Link key='2' to={'/about'}>За Сайта</Link>
                <Link key='1' to={'/contact'}>Контакт</Link>
            </div>

            <div>&#169;Copyrights. All rights reserved {new Date().getFullYear()}.</div>
        </footer>
    );
}

export default Footer;
