import { useLocation } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Tiles from './Tiles';
import Menu from './Menu';
import { SERVER_URL } from './constants/CONST';

function SearchResult() {
    const [products, setProducts] = useState({});
    const query = new URLSearchParams(useLocation().search).get('q');

    useEffect(() => {
        axios.get(SERVER_URL)
            .then(response => response.data)
            .then(data => {
                setProducts(prevProducts => ({
                    ...prevProducts,
                    [null]: filteredData(data, query)
                }));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchResultProducts = products[null];

    return (
        <>
            <Menu activeCategory={null} />
            <Tiles products={searchResultProducts} setProducts={setProducts} category={null} overrideNoProductsText={"Няма намерени резултати за: " + query} />
        </>
    );
}

const filteredData = (data, query) => {
    return data.filter((product) => {
        return product.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
    }).sort((a, b) => b.discount - a.discount)
}

export default SearchResult;