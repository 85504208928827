import './Search.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import SearchSuggestions from './SearchSuggestions';
import { isNull, isUndefined } from 'underscore'
import { SERVER_URL } from './constants/CONST';

function Search() {
    const [names, setNames] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocus] = useState('');
    const suggestions = getSuggestions(names, inputValue.toLowerCase());

    useEffect(() => {
        async function getToken() {
            const names = await axios.get(SERVER_URL)
                .then(response => response.data)
                .then(data => {
                    return data.map(p => p.name);
                });

            setNames(names);
        }
        getToken();
    }, []);

    return (
        <div className="form-wrapper">
            <form className="search-form" action="/search" method="get">
                <input
                    className="search-input"
                    type="text" id="header-search" placeholder="Намери алкохолни оферти..."
                    name="q" value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    onFocus={() => {
                        handleFocusInput();
                        setIsFocus(true);
                    }}
                    onBlur={(e) => {
                        const suggestion = e.relatedTarget?.querySelector('li.suggestion')
                        if (isNull(suggestion) || isUndefined(suggestion)) {
                            handleBlurInput();
                            setIsFocus(false);
                            if (inputValue === '') { document.querySelector('.search-form .close-button')?.classList.add('hidden') }
                        }
                    }}
                />
                <div className='close-button hidden' onClick={() => {
                    document.querySelector('.search-form .close-button')?.classList.add('hidden');
                    setInputValue('');
                }}></div>
                <button className="search-button" type="submit" aria-label="search button"><span className="search-icon">&#128269;</span></button>
            </form>
            <SearchSuggestions input={inputValue} suggestions={suggestions} isFocus={isFocused} />
        </div>
    );
}

function handleFocusInput() {
    document.querySelector('.search-form .close-button')?.classList.remove('hidden');
    document.querySelector('.category-tiles-wrapper')?.classList.add('reduced-opacity');
    document.querySelector('.tiles-wrapper')?.classList.add('reduced-opacity');
}

function handleBlurInput() {
    document.querySelector('.category-tiles-wrapper')?.classList.remove('reduced-opacity');
    document.querySelector('.tiles-wrapper')?.classList.remove('reduced-opacity');
}

function getSuggestions(names, inputValue) {
    let arr = [];

    if (inputValue.length > 2) {
        arr = names.filter((name) => {
            return name.toLowerCase().indexOf(inputValue) > -1;
        });
    }

    return arr;
}

export default Search;
