import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import Footer from './components/Footer';


const withFooter = WrappedComponent => () => [
  <WrappedComponent key="1" />,
  <Footer key="2" className="footer" />
];

const Wrapper = () => {
  return (
    <App className="content" />
  )
}

const WrapperWithFooter = withFooter(Wrapper)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode >
    <BrowserRouter>
      <WrapperWithFooter />
    </BrowserRouter>
  </React.StrictMode>
);
