
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Tiles from './Tiles';
import Menu from './Menu';
import { SERVER_URL } from './constants/CONST';
import { MoonLoader } from 'react-spinners';

function Category() {
    const [products, setProducts] = useState({});
    const [loading, setLoading] = useState(true);
    const category = useParams().category || 'deals';

    useEffect(() => {
        const url = category.indexOf('deals') > -1 ? SERVER_URL : `${SERVER_URL}&category=${category}`;

        axios.get(url)
            .then(response => response.data)
            .then(data => {
                setProducts(prevProducts => ({
                    ...prevProducts,
                    [category]: filteredData(data, category)
                }));
                setLoading(false);
            });

        const filtersElement = document.querySelector('.filters');
        const filters = filtersElement ? filtersElement.children : [];

        for (let i = 0; i < filters.length; i++) {
            filters[i].classList.remove('selected');
        }
    }, [category]);

    return (
        <>
            <Menu activeCategory={category} />
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "13px" }}>
                    <MoonLoader color={"#232f3e"} />
                </div> : <Tiles products={products[category]} category={category} setProducts={setProducts} />}
        </>
    );
}

const filteredData = (data, category) => {
    const dataArr = category === 'deals-price' ?
        data.sort((a, b) => (b.oldPrice - b.price) - (a.oldPrice - a.price)) : data.sort((a, b) => b.discount - a.discount);

    return category.indexOf('deals') > -1 ? dataArr.slice(0, 20) : dataArr;
}

export default Category;
