import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import CategoryComponent from './components/Category';
import SearchResult from './components/SearchResult';
import AboutComponent from './components/About';
import ContactComponent from './components/Contact';

function App(props) {
	return (
		<div {...props}>
			<Header />
			<Routes>
				<Route path="/" element={<Category />} exact />
				<Route path="/category" element={<Category />} exact />
				<Route path="/category/:category" element={<Category />} />
				<Route path="/search" element={<Search />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</div>
	);
}

function Search() {
	return (<><SearchResult /></>)
}

function Category() {
	return (<><CategoryComponent /></>)
}

function About() {
	return (<><AboutComponent /></>)
}

function Contact() {
	return (<><ContactComponent /></>)
}

export default App;
